<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="px-10">
		<div>
			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

				<a-col :span="24" :md="24" :lg="24" :xl="24" class="">
					<a-row :gutter="24" style="margin-top: 0px;">

						<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 mt-10">
							<h5>Calender</h5>
						</a-col>

					</a-row>
				</a-col>
			</a-row>
			
			<div v-if="!loadingData">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

					<a-col :span="24" :md="24" :lg="24" :xl="24" class="">

						<a-row :gutter="24" class="">

							<a-col class="mb-24" style="height: 100%;" :span="24" :md="24" :lg="24" :xl="24" v-for="(group, scheduleDate) in schedules" :key="scheduleDate">

								<a-row :gutter="24">

									<a-col :span="24">
										<h6 class="mb-20">{{ scheduleDate == todayDate ? 'Today' : scheduleDate }}</h6>
									</a-col>

									<a-col class="mb-24" style="height: 100%;" :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="6"  v-for="sche in group" :key="sche.uuid">
										<a-card class=" px-5 h-100">

											<a-row :gutter="24">
												<a-col :span="24" :md="24" :lg="24" :xl="24">
													<a-row :gutter="24">
														<a-col :span="6" :sm="6" :md="6" :lg="6" :xl="4" class="text-center">
															<a-avatar :size="50" class="mt-5">
																<template #icon>
																	<svg class="dark:text-white pt-10" style="color: #444444; width: 28px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
																		<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1H5a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Zm3-7h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Zm-8 4h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Z"/>
																	</svg>
																</template>
															</a-avatar>
														</a-col>

														<a-col :span="18" :sm="18" :md="18" :lg="18" :xl="20">
															
															<a-row :gutter="24" class="mt-5">
																<a-col :span="24" :md="16" :lg="16" :xl="16">
																	<h6 class="mb-0 pb-0">{{ formatName(sche.name.length > 20 ? `${sche.name.substring(0, 20)}...` : sche.name) }}</h6>
																</a-col>
																<a-col :span="24" :md="8" :lg="8" :xl="8" class="text-right" style="height: 30px !important;">
																	<a-button id="liveBtn" type="text" size="small" disabled v-if="checkSessionStarted(sche.startDate, sche.endDate) == 'online'" style="padding-left: 8px; padding-right: 8px; height: 30px !important;">
																		<font-awesome-icon icon="fa-solid fa-circle" class="" style="font-size: 5px !important;"/> 
																		Live 
																	</a-button>
																</a-col>
															</a-row>

															<a-row :gutter="24">
																<a-col :span="24" :md="24" :lg="24" :xl="24">
																	<label>Start at {{ `${$Moment(sche.startDate).format('hh:mm A')} to ${$Moment(sche.endDate).format('hh:mm A')}` }} </label>
																</a-col>
															</a-row>

															<a-row :gutter="24" style="height: 30px !important;">
																<a-col :span="24" :md="12" :lg="12" :xl="12">
																	<small>Created by {{ `${formatName(sche.creator.firstName)} ${formatName(sche.creator.lastName)}` }} </small>
																</a-col>

																<a-col :span="24" :md="12" :lg="12" :xl="12" class="text-right" style="height:40px !important">
																	<a-button type="text" size="small" id="editBtn" class="text-silabu" :href="`/my-classes/view/${sche.clazz}/live-class`" target="_blank" v-if="checkSessionStarted(sche.startDate, sche.endDate) == 'online'">
																		Join Class
																	</a-button>
																</a-col>
															</a-row>

															
														</a-col>

													</a-row>
													
												</a-col>
											</a-row>
											
										</a-card>
									</a-col>
									
								</a-row>
							</a-col>

						</a-row>

					</a-col>

				</a-row>


			</div>

			<div v-if="!loadingData && Object.keys(schedules).length === 0">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 50vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<img src="../../../public/images/no_data.png" alt="" width="70%" style="" class=""> 

						<h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No schedules found</h6>
						
					</a-col>

				</a-row>
			</div>


			<div v-if="loadingData">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 60vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<a-spin class="text-primary" size="large"/>

					</a-col>

				</a-row>
			</div>
			
			<div v-if="!loadingData && schedules == null">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 55vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<label>No schedules found</label>

					</a-col>

				</a-row>
			</div>
		</div>


	</div>
</template>

<script>
    import { notification } from 'ant-design-vue';

	export default ({
		
		components: {
		},

		data() {
			return {
				loadingData: false,
				message: '',
                schedules: [],
				form: this.$form.createForm(this, { name: 'topic_login' }),
				isRepeatable: false,
				schedule: {
					uuid: null,
					title: null,
					position: null,
					description: null,
					loading: false,
					isCreate: true,
					showModal: false,
				},

				repeatitions: [
					{ uuid: 1, name: 'Daily' },
					{ uuid: 7, name: 'Weekly' }, 
					{ uuid: 30, name: 'Monthly' },
					{ uuid: 365, name: 'Yearly' },
				],

				userDetails: {},

				todayDate: this.$Moment(new Date).format('DD MMM YYYY') 
      		}
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Calendar', url: '/calendar', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
			this.getSchedules();
			// this.getUserDetails();
		},

		methods: {

			formatName(name) {
				let formattedName = name.length > 30 ? `${name[0].toUpperCase()}${name.substring(1, 25).toLowerCase()}...` : `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}`;
                return formattedName;
            },

			checkSessionStarted(startDate, endDate) {
				let todayDate = this.$Moment(new Date).format('DD-MM-YYYY'); 
				let currentTime = this.$Moment(new Date).format('HH:mm');
				
				let scheduleDate =  this.$Moment(startDate).format('DD-MM-YYYY');
				let scheduleStartTime =  this.$Moment(startDate).format('HH:mm');
				let scheduleEndTime =  this.$Moment(endDate).format('HH:mm');

				if(todayDate == scheduleDate) {
					if(currentTime >= scheduleStartTime && currentTime <= scheduleEndTime) {
						return 'online';
					}else if(currentTime > scheduleEndTime) {
						return 'over'
					}
					else if(currentTime <= scheduleStartTime) {
						return 'start-soon'
					}
				}else{
					return 'not-yet'
				}

				console.log()
			},

			filterByExpiration (schedules) {
				let today = new Date();
				let formattedDate = this.$Moment(today.toISOString()).format('DD-MM-YYYY');
				// console.log(this.$Moment(today.toISOString().format('DD-MM-YYYY')))
				// console.log(this.$Moment(item.startDate).format('DD-MM-YYYY'))
				// this.$Moment(item.startDate).format('DD-MM-YYYY') >= this.$Moment(today.toISOString().format('DD-MM-YYYY')))
				return schedules.filter((item) => this.$Moment(item.startDate).format('YYYY-MM-DD') >= this.$Moment().format('YYYY-MM-DD'))
			},


			async getSchedules() {

				this.loadingData = true;

                const currentPage = 1; 
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/schedules/mine?orderBy=startDate&order=ASC&populate=true&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						
						console.log(this.filterByExpiration(response.data.data))

						this.schedules = await this.filterByExpiration(response.data.data).reduce((schedule, currentSchedule) => {
							
							const category = this.$Moment(currentSchedule.startDate).format('DD MMM YYYY');

							if (!schedule[category]) {
								schedule[category] = [];
							}
							schedule[category].push(currentSchedule);
							return schedule;
						}, {});
						
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#editBtn, #editBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>